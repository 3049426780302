body, html {
  margin: 0;
  background-color: #0f0f11;
  color: #fff;
}

.web3modal-modal-lightbox.web3modal-modal-lightbox {
  z-index: 9999;
}

.web3modal-modal-card{
  background: linear-gradient(rgb(19, 25, 40) 0px, rgb(19, 25, 40) 0px) padding-box padding-box, linear-gradient(135deg, rgb(90, 249, 196) 0%, rgb(61, 93, 255) 50%, rgb(255, 115, 225) 100%) border-box border-box;
}

.web3modal-provider-container{
  flex-direction: row!important;
}

.web3modal-provider-description{
/*   font-size: large;
  font-family: 'Poppins', sans-serif */
  display: none;
}

.web3modal-provider-name{
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  color: rgb(227, 227, 227);
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}
.animate-spin-slow {
  animation: spin 2s linear infinite;
}